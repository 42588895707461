<template>
    <div class="main">
        <div class="title">编辑资料</div>
        <personal-infos> </personal-infos>
    </div>
</template>

<script>
import PersonalInfos from '../../components/PersonalInfos.vue';
export default {
    components: { PersonalInfos },
    computed: {},
    data() {
        return {};
    },
    methods: {}
};
</script>

<style lang="less" scoped>
.main {
    background-color: #fff;
    margin: 17px 20px 0;
    .title {
        font-size: 16px;
        font-weight: bold;
        color: #000000;
        padding-bottom: 17px;
        line-height: 26px;
        border-bottom: 1px solid @bg;
    }
}
</style>
