<template>
    <div>
        <!-- <div v-if="this.$route.name == 'userEdit'">
            <div class="avatar"> -->
        <!-- <crop-upload style="margin-left: 54px" v-model="userInfo.avatar"></crop-upload> -->
        <!-- <crop-upload style="margin-left: 54px" v-model="sizeForm.avatar"></crop-upload> -->
        <!-- <div style="position: relative; z-index: 99; margin-left: -8px; margin-top: 10px">
                    <el-button @click="uploadFileFn(sizeForm)">修改头像</el-button>
                </div> -->
        <!-- <input id="uploadFile" @change="upload" type="file" accept="jpg,png,gif,jpeg" v-show="false" /> -->
        <!-- </div>
        </div> -->
        <div class="top">
            <el-form ref="form" :model="sizeForm" :rules="registerRule">
                <el-form-item label="真实姓名" prop="nickname">
                    <el-input
                        style="width: 200px"
                        placeholder="请输入您的真实姓名"
                        v-model="sizeForm.nickname"
                        :disabled="whether"
                    ></el-input>
                </el-form-item>
                <el-form-item prop="type" label="证件类型">
                    <el-select
                        v-model="sizeForm.type"
                        @change="change(sizeForm)"
                        style="width: 300px"
                        clearable
                        filterable
                        :disabled="whether"
                    >
                        <el-option
                            v-for="item in typeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="证件编号" prop="documentNumber">
                    <el-input
                        style="width: 300px"
                        placeholder="请输入您的证件编号"
                        v-model="sizeForm.documentNumber"
                        :disabled="whether"
                    ></el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="phone">
                    <el-input
                        style="width: 200px"
                        placeholder="请输入您的联系方式"
                        :disabled="true"
                        v-model="sizeForm.phone"
                    ></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="email">
                    <el-input style="width: 200px" placeholder="请输入" v-model="sizeForm.email" :disabled="whether"></el-input>
                </el-form-item>
                <el-form-item :label="name" prop="photoFront">
                    <single-upload class="upload" v-model="sizeForm.photoFront" :disabled="whether"></single-upload>
                </el-form-item>
                <div class="description">{{ explain }}</div>
                <el-form-item :label="label" prop="photoBackside">
                    <single-upload class="upload" v-model="sizeForm.photoBackside" :disabled="whether"></single-upload>
                </el-form-item>
                <div class="description">{{ explain }}</div>
            </el-form>
            <div class="reminder">
                <div class="reminder_tip">温馨提示</div>
                <div class="reminder_content">
                    <div>
                        1、普通实名认证提交申请后平台会在2个工作日内完成认证审核，审核通过前账户实名认证状态为审核中。
                    </div>
                    <div>
                        2、若您已开通支付宝并且支付宝账户已进行实名认证，建议您您选择芝麻信用认证进行实名认证审核。
                    </div>
                    <div>3、实名认证审核一旦通过将无法修改，请您谨慎填写。</div>
                </div>
            </div>
            <div class="btn">
                <el-button type="primary" @click="individualReturn"  v-if="whether">返回</el-button>
                <el-button type="primary" @click="onSubmit" v-else>提交申请</el-button>
                <!-- <el-button type="info" @click="$router.go(-1)">返回</el-button> -->
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    data() {
        return {
            sizeForm: {
                nickname: '',
                documentNumber: '',
                photoFront: '',
                photoBackside: '',
                phone: '',
                email: '',
                // avatar: 'https://zhirongip.oss-cn-hangzhou.aliyuncs.com/image/2021-06-30-17-02-42uzAOUPcw.jpeg',
                type: 'ID_CARD'
            },
            typeFl: {},
            registerRule: {
                documentNumber: [
                    {
                        required: true,
                        validator: (rule, value, callback) => {
                            if (this.sizeForm.type === 'ID_CARD') {
                                const reg = /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
                                if (!reg.test(value) && value != '') {
                                    callback(new Error('请输入正确的身份证号'));
                                } else {
                                    callback();
                                }
                            } else {
                                var reg = /^[\u4E00-\u9FA5](字第)([0-9a-zA-Z]{4,8})(号?)$/;
                                if (reg.test(value) === false) {
                                    callback(new Error('军官证号不合规'));
                                } else {
                                    callback();
                                }
                            }
                        }
                    }
                ],
                nickname: { required: true, message: '请输入您的真实姓名', trigger: 'blur' },
                phone: { required: true, message: '请输入您的联系方式', trigger: 'blur' },
                email: { type: 'email', required: true, message: '请输入您的邮箱', trigger: 'blur' },
                photoFront: { required: true, message: '请添加您的证件（正面）', trigger: 'blur' },
                photoBackside: { required: true, message: '请添加您的证件（反面）', trigger: 'blur' },
                type: { required: true, message: '请选择', trigger: 'blur' }
            },
            typeOptions: [
                { label: '身份证', value: 'ID_CARD' },
                { label: '军官证', value: 'OFFICER_ID' }
            ],
            whether: false
        };
    },
    computed: {
        ...mapState(['userInfo', 'personalInfo']),
        explain() {
            const list = [
                '请上传身份证图片，格式JPG或PNG或JPEG，大小不超过1M',
                '请上传证件照图片，格式JPG或PNG或JPEG，大小不超过1M'
            ];
            return list[this.typeFl == 'ID_CARD' ? 0 : 1];
        },
        type() {
            return this.userInfo.authorities.find(item => {
                return item.name == 'ROLE_INSTITUTION' || item.name == 'ROLE_PERSONAL';
            });
        }
    },
    created() {
        this.$http
            .get('/personal/my')
            .then(res => {
                if (res.status === 'PASS') {
                    this.whether = true;
                }
                this.sizeForm = {
                    ...res,
                    nickname: this.userInfo.nickname,
                    phone: this.userInfo.phone,
                    avatar: this.userInfo.avatar,
                    email: this.userInfo.email
                };
            })
            .catch(e => {
                console.log(e);
            });
        (this.sizeForm.nickname = this.userInfo.nickname), (this.sizeForm.phone = this.userInfo.phone);
        this.change({
            type: 'ID_CARD'
        });
    },
    methods: {
        individualReturn(){
            this.$emit('change-types', 1)
        },
        uploadFileFn() {
            document.getElementById('uploadFile').click();
        },
        upload(e) {
            this.avatar = window.URL.createObjectURL(e.target.files[0]);
        },
        change(e) {
            this.typeFl = e.type;
            if(this.sizeForm.documentNumber){
                this.sizeForm.documentNumber = '';
            }
            if (this.typeFl == 'ID_CARD') {
                this.name = '身份证 (正面)';
                this.label = '身份证 (反面)';
            } else {
                this.name = '证件照片(正面)';
                this.label = '证件照片(反面)';
            }
        },
        onSubmit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.$confirm('实名认证审核一旦通过将无法修改，请您谨慎填写', '提示', { type: 'warning' }).then(
                        () => {
                            this.preservation();
                        }
                    );
                }
            });
        },
        preservation() {
            let data = { ...this.sizeForm };
            delete data.nickname;
            delete data.phone;
            delete data.email;
            data.userId = this.userInfo.id;
            this.saving = true;
            let userInfo = {
                ...this.userInfo,
                nickname: this.sizeForm.nickname,
                phone: this.sizeForm.phone,
                // avatar: this.sizeForm.avatar,
                email: this.sizeForm.email
            };
            userInfo.authorities = [
                ...userInfo.authorities,
                {
                    name: 'ROLE_PERSONAL'
                }
            ];

            this.$http
                .post('user/save', userInfo, {
                    body: 'json'
                })
                .then(res => {
                    this.sizeForm.avatar = res.avatar;
                    this.$store.dispatch('getUserInfo');
                    return this.$http.post('/personal/save', data, { body: 'json' }).then(res => {
                        console.log(res);
                        this.sizeForm = res;
                        this.saving = false;
                        this.$store.dispatch('getUserInfo');
                        this.$router.push('/Authentication');
                    });
                })
                .catch(e => {
                    console.log(e);
                    this.saving = false;
                    this.$message.error(e.error);
                });
        }
    }
};
</script>

<style lang="less" scoped>
/deep/ .el-form {
    margin-top: 30px;
}
/deep/ .el-form-item__label {
    width: 125px;
    white-space: nowrap;
    color: #313233;
    text-overflow: ellipsis;
    line-height: 40px;
    font-size: 13px;
}
/deep/ .el-input__inner {
    height: 36px;
    line-height: 36px;
}
/deep/ .el-form-item__error {
    margin-left: 128px;
}
/deep/ .el-button {
    width: 110px;
    border-radius: 4px;
    margin-left: 30px;
}
.upload {
    .flex();
}
.btn {
    margin: 60px 0 0 98px;
}
/deep/ .el-button {
    width: 220px;
    height: 36px;
    background: #01a041;
    border-radius: 4px;
}
/deep/ .el-button--primary {
    border-color: #01a041;
}
.avatar {
    margin: 30px 0 -220px 550px;
}
.description {
    font-size: 14px;
    font-weight: 400;
    color: #c8c9cc;
    line-height: 24px;
    margin: -11px 0 20px 125px;
}
.reminder {
    display: flex;
    margin-left: 70px;
    margin-top: 6px;
    .reminder_tip {
        font-size: 13px;
        font-weight: 400;
        color: #313233;
        margin-right: 10px;
        line-height: 24px;
    }
    .reminder_content {
        font-size: 14px;
        font-weight: 400;
        color: #626366;
        line-height: 24px;
    }
}
</style>
